console.log("LOADED: expiration_modal_view.js");

define('vendor/views/expiration_modal_view',[
	'jquery',
	'underscore',
	'backbone',
	'mustache',
	'accounting',
	'vendor/views/cj_modal',
	'stache!vendor/tpl/expiration_modal_step_1',
	'stache!vendor/tpl/expiration_modal_step_2'],

	function(
		jQuery,
		_,
		Backbone,
		Mustache,
		accounting,
		CJModal,
		ExpirationModalTpl1,
		ExpirationModalTpl2
	) {

		var ExpirationModalStep1 = Backbone.View.extend({
			events: {},

			initialize: function(options) {
				this.parentView = options.parentView;
			},

			render: function() {
				this.$el.html(ExpirationModalTpl1({
					exp_month_choices: window.cj.exp_months,
					exp_year_choices: window.cj.exp_years,
					countries: window.cj.countries,
					order: this.parentView.order
				}));
			},

			postRender: function() {
				this.$el.find("#number").focus();
			},

			validate: function() {
				var valid = this.$el.find("#expire-form")[0].checkValidity();
				if (!valid) {
					this.$el.find(':submit').click();
				}
				return valid;
			},

			submit: function() {
				this.parentView.cc = this.$el.find('#number').val().slice(-4);
				return jQuery.ajax({
					type: "POST",
					url: '/payment_info/card_expired',
					data: this.$el.find('#expire-form').serialize(),
					dataType: "json"
				});
			}
		});

		var ExpirationModalStep2 = Backbone.View.extend({
			events: {},

			initialize: function(options) {
				this.parentView = options.parentView;
			},

			render: function() {
				this.$el.html(ExpirationModalTpl2({
					order: this.parentView.order,
					cc: this.parentView.cc
				}));
			}
		});

		var ExpirationModalView = CJModal.extend({
			initialize: function (options) {
				_.bindAll(this, 'translateOrder');
				options.modalId = 'billing-modal';
				options.modalClass = 'billing-modal';

				this.order = {};
				if (window.cj.recentUnpaidOrder !== null) {
					this.order = this.translateOrder(window.cj.recentUnpaidOrder);
				}

				options.steps = [
					{
						name: "step1",
						body: ExpirationModalStep1,
						model: this.model,
						button: {
							list: [
								{
									template: '.modal-button-templates .modal-next',
									label: 'Update & Pay ' + this.order.total_display + ' Balance',
									class: 'btn-positive',
									id: 'submit-pay',
									position: 'center'
								}
							]
						}
					},
					{
						name: "step2",
						size: "small",
						modalClass: 'expired-confirm',
						body: ExpirationModalStep2,
						model: this.model,
						button: {
							list: [
								{
									template: '.modal-button-templates .modal-cancel',
									label: 'Great!',
									class: 'btn-positive'
								}
							]
						}
					}
				];

				CJModal.prototype.initialize.call(this, options);
			},

			translateOrder: function(order) {
				accounting.settings.currency.symbol = "$";
				order.total_price_display = accounting.formatMoney(order.total_price / 100.0);
				order.total_pending_fees_display = accounting.formatMoney(order.total_pending_fees / 100.0);
				order.total_app_fees_display = accounting.formatMoney(order.total_app_fees / 100.0);
				order.total_tax_display = accounting.formatMoney(order.total_tax / 100.0);
				order.credit_applied_display = accounting.formatMoney(order.credit_applied / 100.0);
				order.total_display = accounting.formatMoney(order.total / 100.0);
				return order;
			}
		});

		return ExpirationModalView;
	});

