// https://help.fullstory.com/develop-js/137380
function FullstoryUtil() {

  var fsNamespace = 'vendorFS';

	function set(key, val) {
		if ( typeof window !== 'object' || ! window[fsNamespace] ) {
			return;
		}

		var vars = {};
		vars[key] = val;
		window[fsNamespace].setUserVars(vars);
	}

	function setBool(key, val) {
		// key must be camelCase
		return set(key + '_bool', val);
	}

	function setReal(key, val) {
		// key must be camelCase
		return set(key + '_real', val);
	}

	function setStr(key, val) {
		// key must be camelCase
		return set(key + '_str', val);
	}

	function setInt(key, val) {
		// key must be camelCase
		return set(key + '_int', val);
	}

	function setDate(key, val) {
		// key must be camelCase
		// val must be a javascript Date() object
		return set(key + '_date', val);
	}

	function identify(customerId, name, email) {

		if ( typeof window !== 'object' || ! window[fsNamespace] ) {
			console.debug('tried to identify during SSR or FS not loaded');
			return;
		}

		window[fsNamespace].identify(customerId, {
			displayName: name || '',
			email: email || '',
		});

	}

	function _sendUrlToSentry() {
		var fullstorySessionUrl = typeof window === 'object' && window[fsNamespace] && window[fsNamespace].getCurrentSessionURL();
		if (fullstorySessionUrl && window.Raven) {
			Raven.setExtraContext({ fullstorySessionUrl: fullstorySessionUrl });
		}
		window.removeEventListener('fsReady', _sendUrlToSentry, false);
	}

	function sendUrlToSentry() {
		if (typeof window !== 'object') return;

		if (window.cj.fsReady) {
			return _sendUrlToSentry();
		}

		window.addEventListener('fsReady', _sendUrlToSentry, false);
	}

	return {
		identify: identify,
		setBool: setBool,
		setReal: setReal,
		setStr: setStr,
		setInt: setInt,
		setDate: setDate,
		sendUrlToSentry: sendUrlToSentry,
	};
}


if (typeof require === 'function' && typeof exports !== 'undefined') {
  module.exports = FullstoryUtil();
} else {
  define('vendor/components/fullstory_util',[], FullstoryUtil);
}
;
