console.log("LOADED: cj_modal.js");

define('vendor/views/cj_modal',[
		'jquery',
		'underscore',
		'backbone',
		'mustache',
		'stache!vendor/tpl/cj_modal'],

	function (jQuery,
			  _,
			  Backbone,
			  Mustache,
			  ModalTpl) {

		var CJModalView = Backbone.View.extend({
			events: {
				"click .modal-complete-positive": "onComplete",
				"click .modal-complete-serious": "onComplete",
				"click .modal-cancel": "onCancel",
				"click .modal-back": "onBack",
				"click .modal-next": "onNext",
				"click .modal-delete": "onDelete",
				"submit form": "onSubmitForm"
			},

			defaultErrorMsg: 'Oops, it looks like there was a problem. Please contact <a href="' + window.cj.support_href + '" target="_blank">Support</a>.',

			initialize: function (options) {
				_.bindAll(this, 'onComplete', 'onCancel', 'onBack', 'onDelete', 'onNext', 'closeModal', 'onSubmitForm', 'showError', 'showLoader', 'hideLoader', 'current');
				this._currentIndex = 0;
				this._modalId = options.modalId || '';
				this._modalClass = options.modalClass || '';
				this._steps = options.steps;
				this._reloadOnComplete = options.reloadOnComplete || false;
				this._cleanupOnClose = options.cleanupOnClose || true;

				// bootstrap modal option overrides
				this.bootstrapOpts = {
					keyboard: false
				};

				if (options.backdrop) {
					this.bootstrapOpts.backdrop = options.backdrop;
				}
				if (options.keyboard) {
					this.bootstrapOpts.keyboard = options.keyboard;
				}

				if (!this._steps) {
					console.log("There are no steps defined so nothing more to render.");
					return;
				}

				this._steps.forEach(function (s) {
					s.body = new s.body({
						model: this.getModel(s),
						parentView: this,
						parentOptions: s.options
					});
				}, this);
			},

			render: function () {
				var self = this;

				// Don't re-add the modal to the DOM if we don't have to
				var existingModal = jQuery('#' + this._modalId);
				if (existingModal.length === 0) {
					this.$el = jQuery(ModalTpl(this.model));

					this.setModalId(this._modalId);

					this.setModalClass(this._modalClass);
				}
				else {
					this.$el = existingModal;
				}

				var modal = this.$el.modal(this.bootstrapOpts);
				// need this for when the user closes the modal by clicking away, not the close buttons
				modal.on('hidden.bs.modal', function () {
					self.onCancel();
				});

				// render first step
				this.renderStep(0);
			},

			renderStep: function (index) {
				this.hideLoader();
				this._currentIndex = index;
				var step = this.current();

				// step can override base modal class by providing a new modalClass
				if (step.modalClass) {
					this.setModalClass(step.modalClass);
				}

				if (step.size) {
					if (!this.$el.hasClass(step.size)) {
						this.$el.find('.modal-dialog').addClass(step.size);
					}
				} else {
					this.$el.find('.modal-dialog').addClass('.modal-dialog').siblings().removeClass();
				}

				this.$el.find('.modal-message-container').toggleClass('hidden', true);
				if (step.main_message) {
					this.setMainMessage(step.main_message);
				} else {
					this.unSetMainMessage();
				}

				if (step.name) {
					this.setModalDialogId(this._modalId + '_' + step.name);
				} else {
					this.setModalDialogId(this._modalId + '_step' + this._currentIndex);
				}

				this.renderTitle(step);

				if (step.showClose === false) {
					this.$el.find('.close').hide();
				} else {
					this.$el.find('.close').show();
				}

				var render = step.body.render();
				if (render === false) {
					// if return false out of render then stop this step from rendering. Used when calling renderStep
					// from a render
					return false;
				}

				// replace modal body with current step's body template
				this.$el.find('.modal-body').html(step.body.el);

				if (step.button) {
					var buttons = _.map(step.button.list, function (obj) {
						var btn = this.$el.find(obj.template).clone();

						if (obj.label) {
							btn.html(obj.label);
						}

						if (obj.id) {
							btn.attr('id', obj.id);
						}

						if (obj.class) {
							btn.removeClass(function (index, css) {
								return (css.match(/(^|\s)btn-\S+/g) || []).join(' ');
							});
							btn.addClass(obj.class);
						}

						if (obj.position) {
							// remove default pull-orientation
							btn.removeClass(function (index, css) {
								return (css.match(/(^|\s)pull-\S+/g) || []).join(' ');
							});

							if (obj.position == 'left') {
								btn.addClass('pull-left');
							}
							else if (obj.position == 'right') {
								btn.addClass('pull-right');
							}
						}

						// For each attr we passed it, add it to the element
						_.each(obj.attrs, function (value, key) {
							btn.prop(key, value);
						});

						btn.prop('disabled', obj.btn_disabled || false);

						return Mustache.to_html(btn[0].outerHTML, this.getModel(step, 'button'));
					}, this);
					this.$el.find('.modal-buttons').html(buttons.join("\n"));
				} else {
					this.$el.find('.modal-buttons').empty();
				}

				// call a post render function per step if available
				if (step.body.postRender) {
					step.body.postRender();
				}

				step.body.delegateEvents();
				this.delegateEvents();
			},


			/**
			* Factor title rendering out into its own function so option's events can change
			* the title of the option
			**/
			renderTitle: function(step, altHTML) {
				if (step.title) {
					this.$el.find('.modal-header').show();
					if (altHTML) {
						this.$el.find('.modal-title').html(Mustache.to_html(altHTML, this.getModel(step, 'title')));
					} else {
						this.$el.find('.modal-title').html(Mustache.to_html(step.title.html, this.getModel(step, 'title')));
					}
				} else {
					this.$el.find('.modal-header').hide();
				}
			},

			/**
			* Render extra content in the lower-left corner of the modal.
			* Make sure there is enough space to the left of any buttons!
			*/
			renderExtraFooter: function(content) {
				this.$el.find('.modal-extra-footer').html(content);
			},

			getModel: function (step, key) {
				// the model can be on the sub key level as in "title" or "buttons" or at the root of the step obj
				// if the model is a func then execute it
				var model;
				if (step[key]) {
					model = step[key].model || step.model || {};
				} else {
					model = step.model || {};
				}

				return _.isFunction(model) ? model() : model;
			},

			current: function () {
				return this._steps[this._currentIndex];
			},

			_closeModalOnComplete: function() {
				this.closeModal();

				if (this._reloadOnComplete) {
					window.location.reload();
				}
			},

			onComplete: function (event) {
				var step = this.current();

				// do not continue with completion steps if the modal is not valid.
				if (!this.checkValidation(step, 'complete')) {
					return false;
				}

				var $btn;
				if (event) {
					$btn = jQuery(event.currentTarget);
					$btn.prop('disabled', true);
					// prevents complete event from firing more than once
					event.stopPropagation();
				}

				this.showLoader();
				this.hideError();
				var msg = this.defaultErrorMsg;

				if (!step.body.onComplete) {
					this._closeModalOnComplete();
					return;
				}

				// Attempt complete
				// returns an ajax promise
				var promise = step.body.onComplete();
				if(promise) {
					promise.success(_.bind(function (data) {
						if (data.success) {
							this._closeModalOnComplete();
							return true;
						} else {
							var error = msg;
							if (data.errors) {
								error = data.errors[0];
							}
							else if (data.message) {
								error = data.message;
							}
							this.showError(error);
							if (!_.isUndefined($btn)) {
								$btn.prop('disabled', false);
							}
							return false;
						}
					}, this));
					promise.error(_.bind(function (resp) {
						if (resp.responseJSON) {

							var data = resp.responseJSON;
							msg = data.errors[0];

							if (step.body.onCustomError) {
								return step.body.onCustomError(msg, this.current());
							}
						}
						this.showError(msg);
						if (!_.isUndefined($btn)) {
							$btn.prop('disabled', false);
						}
						return false;
					}, this));
				} else {
					this._closeModalOnComplete();
				}
			},

			onCancel: function () {
				// call step's onCancel first
				var step = this.current();
				if (step.body.onCancel) {
					step.body.onCancel();
				}

				// clean up
				this.closeModal();
				if(this._cleanupOnClose) {
					this.$el.remove();
				}
			},

			onDelete: function() {
				var step = this.current();

				if (step.onDelete) {
					step.onDelete();
				} else if (step.body.onDelete) {
					step.body.onDelete();
				} else {
					this.onNext();
				}
			},

			onBack: function () {
				if (this._currentIndex > 0) {
					this.renderStep(this._currentIndex - 1);
				}
			},

			onNext: function () {
				this.showLoader();
				this.hideError();

				// Check if current step needs to validate or make a request before proceeding
				// Otherwise proceed to the next step
				var step = this.current();
				var promise = false;
				var msg = this.defaultErrorMsg;

				// Attempt validation
				if (step.body.validate) {
					var valid = step.body.validate('next');
					if (!valid) {
						this.hideLoader();
						return false;
					}
				}

				if (step.body.onNext) {
					step.body.onNext();
				}

				// Attempt submit
				if (step.body.submit) {
					// returns an ajax promise
					promise = step.body.submit();

					if (promise) {
						promise.success(_.bind(function (data) {
							if (!data.success && step.body.submitHideError !== true) {
								var error = msg;
								if (data.errors) {
									error = data.errors[0];
								}
								this.showError(error);
								return false;
							} else {
								// If we get here, everything was successful, go to next step
								if (this._currentIndex < this._steps.length - 1) {
									this.renderStep(this._currentIndex + 1);
								}
							}
						}, this));
						promise.error(_.bind(function () {
							this.showError(msg);
							return false;
						}, this));
					} else {
						promise = false;
					}
				}

				// If we get here, everything was successful, go to next step
				if (this._currentIndex < this._steps.length - 1 && promise === false) {
					this.renderStep(this._currentIndex + 1);
				}
			},

			enableComplete: function (bool) {
				var completeBtn = this.$el.find('.modal-complete,.modal-complete-positive,.modal-complete-serious');

				completeBtn.toggleClass('disabled', !bool);
				completeBtn.prop('disabled', !bool);
			},

			enableNext: function (bool) {
				var nextBtn = this.$el.find('.modal-next');
				nextBtn.toggleClass('disabled', !bool);
				nextBtn.prop('disabled', !bool);
			},

			setModalId: function (val) {
				this.$el.prop('id', val);
			},

			showLoader: function () {
				var loader = this.$el.find('.loading-content');
				loader.siblings().hide();
				loader.show();
			},

			hideLoader: function () {
				var loader = this.$el.find('.loading-content');
				loader.siblings().show();
				loader.hide();
			},

			checkValidation: function(step, action) {
				// Attempt validation if there is a validation method, and if invalid, display any validation errors
				// in the error section of the modal.
				if (step.body.validate) {
					var valid = step.body.validate(action);
					if (!valid) {
						this.hideLoader();

						if (step.body.getValidationErrorMessage) {
							this.showError(step.body.getValidationErrorMessage());
						}

						return false;
					}
				}

				return true;
			},

			setModalClass: function (val) {
				if (val) {
					this.$el.addClass(val);
				}
			},

			setModalDialogId: function (val) {
				this.$el.find('.modal-dialog').prop('id', val);
			},

			hideMessageContainer: function () {
				var messageContainer = this.$el.find('.modal-message-container');
				if (!messageContainer.text()) {
					this.$el.find('.modal-message-container').toggleClass('hidden', true);
				}
			},

			setMainMessage: function (val) {
				this.$el.find('.modal-main-message-container').removeClass('hidden');
				this.$el.find('.modal-main-message').html(val);
				this.$el.find('.modal-message-container').toggleClass('hidden', false);

				if (this.$el.find('.modal-error-container').text()) {
					this.$el.find('.error-container .modal-section-separator').toggleClass('hidden', false);
				}
			},

			unSetMainMessage: function () {
				this.$el.find('.modal-main-message-container').addClass('hidden');
				this.$el.find('.modal-main-message').html('');
				this.hideMessageContainer();

				this.$el.find('.error-container .modal-section-separator').toggleClass('hidden', true);
			},

			openModal: function () {
				this.$el.modal();
				jQuery('body').addClass('modal-open');
			},

			closeModal: function () {
				jQuery('body').removeClass('modal-open');
				jQuery('.modal-backdrop').remove();
				this.$el.modal('hide');
			},

			showError: function (message) {
				message = message || this.defaultMessage;
				this.hideLoader();
				this.$el.find('.modal-content .error-container .error-text').html(message);
				this.$el.find('.modal-content .error-container').toggleClass('hidden', false);
				this.$el.find('.modal-message-container').toggleClass('hidden', false);
				if (!this.$el.find('.modal-main-message-container').text()) {
					this.$el.find('.error-container .modal-section-separator').toggleClass('hidden', true);
				}
			},

			hideError: function () {
				this.$el.find('.modal-content .error-container .error-text').empty();
				this.$el.find('.modal-content .error-container').toggleClass('hidden', true);
				this.hideMessageContainer();
			},

			onSubmitForm: function (e) {
				// Don't allow accidental submit if enter is hit.
				e.preventDefault();
			},

			// to be called when there is an externally captured error,
			// but it needs to be displayed in the modal
			triggerError: function (message) {
				// disable the buttons
				this.enableComplete(false);
				this.enableNext(false);
				this.showError(message);
			},

			clearModalBody: function () {
				this.$el.find('.modal-body').toggleClass('hidden', true);
			}
		});
		return CJModalView;
	}
);

