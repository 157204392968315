console.log("LOADED: global_notifications:exports");

define('vendor/components/global_notifications/exports',['underscore',
		'jquery',
		'backbone',
		'stache!vendor/components/global_notifications/tpl/export_success',
		'stache!vendor/components/global_notifications/tpl/export_failure',
		'stache!vendor/components/global_notifications/tpl/export_pending',
		'vendor/components/cookie_model'], function(
			_,
			jQuery,
			Backbone,
			SuccessTpl,
			FailureTpl,
			PendingTpl,
			CookieModel) {

		var failedRetries = {};
		var alreadyPending = {};
		var alertNextTime = {};

		var Success = Backbone.View.extend({
			tagName: 'div',

			template: SuccessTpl,

			events: {
				"click .dismiss-label" : "dismissNotif",
				"click .download-trigger": "download"
			},

			initialize: function (options) {

				this.model = options.model;

				this.listenTo(this.model, 'change', this.render);
				this.listenTo(this.model, 'destroy', this.remove);
			},

			render: function () {
				this.$el.html(this.template(this.model.toJSON()));
				return this;
			},

			dismissNotif: function () {
				this.$el.css({
					'min-height': 0
				});
				this.$el.slideUp(180, _.bind(this.model.destroy, this.model));
			},

			download: function () {
				// Note that the actually 'downloading' is done by the browser through the href tag
				setTimeout(_.bind(this.dismissNotif, this), 2000);
			}
		});

		var Pending = Backbone.View.extend({
			tagName: 'div',

			template: PendingTpl,

			events: {
				"click .dismiss-label" : "dismissNotif"
			},

			initialize: function (options) {

				this.model = options.model;
				this.parent = options.parent;

				this.listenTo(this.model, 'change:conflict', this.setConflict);
				// this.listenTo(this.model, 'change:State', this.render);
				// this.listenTo(this.model, 'change:Progress', this.render);
				this.listenTo(this.model, 'destroy', this.remove);
			},

			setConflict: function () {
				alreadyPending[this.model.id] = true;
				alertNextTime[this.model.id] = true;

				this.model.set('Dismissed', false);  // We want to show this again
			},
			render: function () {

				if (this.model.get('Dismissed')) {
					if (!alreadyPending[this.model.id]) {
						this.$el.html("");
						return this;
					} else {
						this.model.set('Dismissed', false);  // We want to show this again
					}
				}

				if (alreadyPending[this.model.id]) {
					this.model.set('conflicted', true);
				}
				this.$el.html(this.template(this.model.toJSON()));

				if (alertNextTime[this.model.id]) {
					alertNextTime[this.model.id] = false;

					this.parent.trigger('forceOpen');

					this.parent.setCollapse('Opened', _.bind(function () {
						var $globalLabel = this.$('.global-label');
						$globalLabel.addClass('pulse');
						// Remove class shortly after for repeatability
						setTimeout(function () {
							$globalLabel.removeClass('pulse');
						}, 500);
					}, this));
				}
				return this;
			},

			dismissNotif: function () {

				// Remove the override that forces it to show even when pending
				alreadyPending[this.model.id] = false;

				this.$el.css({
					'min-height': 0
				});
				this.$el.slideUp(180, _.bind(this.model.destroy, this.model));
			}
		});

		var Failure = Backbone.View.extend({
			tagName: 'div',

			template: FailureTpl,

			events: {
				"click .dismiss-label" : "dismissNotif",
				"click .requeue-trigger": "requeueExport"
			},

			initialize: function (options) {

				this.model = options.model;

				this.listenTo(this.model, 'change', this.render);
				this.listenTo(this.model, 'destroy', this.remove);
			},

			render: function () {
				this.$el.html(this.template(this.model.toJSON()));

				if (failedRetries[this.model.id]) {
					this.$('.try-again-span').html('We could not retry your export. Contact <a href="' + window.cj.support_href + '" target="_blank">Customer Support</a> for help.');
				}

				return this;
			},

			dismissNotif: function () {
				this.$el.css({
					'min-height': 0
				});
				this.$el.slideUp(180, _.bind(this.model.destroy, this.model));
			},

			requeueExport: function () {

				jQuery.ajax({
					type: 'POST',
					url: '/exports/' + this.model.get('export_id') + '/requeue'
				}).success(_.bind(function () {
					
					// Re-render this has a Pending job until the next poll interval
					this.template = PendingTpl;

				}, this)).error(_.bind(function () {

					// Push this state 'globally' (to the module) so it will persist between polls
					failedRetries[this.model.id] = true;

				}, this)).always( _.bind(this.render, this) );
			}
		});


		return {
			'Success': Success,
			'Pending': Pending,
			'Failure': Failure
		};
	});


