console.log("LOADED: cratejoy_payments_setup_issues_modal_view.js");

define('vendor/views/cratejoy_payments_setup_issues_modal_view',[
	'underscore',
	'backbone',
	'vendor/views/cj_modal',
	'stache!vendor/tpl/cratejoy_payments_setup_issues_modal'
],
	function (
		_,
		Backbone,
		CJModal,
		CratejoyPaymentsSetupIssuesModalTpl
	) {

		var CratejoyPaymentsSetupIssuesModal = Backbone.View.extend({
			events: {},

			initialize: function (options) {
				this.parentView = options.parentView;
			},

			render: function () {
				this.$el.html(CratejoyPaymentsSetupIssuesModalTpl({}));
			},

			submit: function () {
			},

			onComplete: function () {
				window.open(window.cj.stripeExpressLink, '_blank');
			},

			onCancel: function () {
			}
		});

		var CratejoyPaymentsSetupIssuesModalView = CJModal.extend({
			initialize: function (options) {
				options.modalId = 'billing-modal';
				options.modalClass = 'billing-modal';

				options.steps = [
					{
						name: "step1",
						body: CratejoyPaymentsSetupIssuesModal,
						model: this.model,
						button: {
							list: [
								{
									template: '.modal-button-templates .modal-cancel',
									label: 'Dismiss',
									class: 'dont-connect',
									id: 'refuse',
									position: 'left'
								},
								{
									template: '.modal-button-templates .modal-complete-positive',
									label: 'Go to Stripe Express',
									class: 'btn-positive',
									id: 'setup-cjp',
									position: 'right'
								}
							]
						}
					}
				];
				CJModal.prototype.initialize.call(this, options);
			},

		});

		return CratejoyPaymentsSetupIssuesModalView;
	});

