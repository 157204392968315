console.log("LOADED: messenger.js");

define('common/components/messenger',[
		'jquery',
		'underscore',
		'backbone',
		'messenger'],
	function(
		jQuery,
		_,
		Backbone,
		Messenger) {

	Messenger.options = {
		extraClasses: 'messenger-fixed messenger-on-bottom messenger-on-right',
		theme: 'block'
	};

	var msg = Messenger();

	// By default this would make any Backbone.sync operation (like save, fetch)
	// keep retrying. Since this alters how backbone works in an obscure way it should
	// not be enabled.
	msg.hookBackboneAjax({
		retry: false
	});

	return msg;
});

