console.log("LOADED: base_page.js");

define('vendor/pages/base_page',[
	'jquery',
	'jquery-cookie',
	'bootstrap',
	'underscore',
	'bootbox',
	'common/components/messenger',
	'vendor/components/search_autocomplete',
	'vendor/components/frontend_stats',
	'vendor/components/fullstory_util',
	'vendor/views/expiration_modal_view',
	'vendor/views/cratejoy_payments_modal_view',
	'vendor/views/upgrade_notice_modal_view',
	'vendor/views/cratejoy_payments_setup_issues_modal_view',
	'vendor/views/new_cratejoy_payments_screen_modal_view',
	'vendor/components/global_notifications',
],
	function (
		jQuery,
		jQueryCookie,
		bootstrap,
		_,
		bootbox,
		Messenger,
		SearchAuto,
		Stats,
		FullstoryUtil,
		ExpirationModalView,
		CratejoyPaymentsModalView,
		UpgradeNoticeModalView,
		CratejoyPaymentsSetupIssuesModalView,
		NewPaymentsScreenModalView,
		GlobalNotifications
	) {

		$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
			// allows for beforeSend() overrides if wanted
			if (!options.beforeSend) {
				options.beforeSend = function (xhr) {
					// cj.store may be undefined or cj.store.id may be undefined.  only add the header if it's defined
					// without this check some pages will be broken, especially login flow
					var storeExists = !!window.cj.store && !!window.cj.store.id;
					if (storeExists) {
						xhr.setRequestHeader('X-Store-Id', window.cj.store.id);
					}
				};
			}
		});

		try {
			window.cj.set_cj_basepath();
		} catch (e) {
			// we're in a codepath that didn't load vendor server_js_embeds, like admin.
			window.cj.basepath = false;
		}

		jQuery('#header-logout').on('click', function (e) {
			Stats.logout();
		});

		// Make bootbox dialogs be dismissable by clicking outside of the dialog.
		jQuery('body').on('show.bs.modal', '[class*="bootbox"]', function (e) {
			$modal = jQuery(e.target);
			$modal.data('bs.modal').options.backdrop = true;
		});

		// Setup and Bind Upgrade Modals
		jQuery('.upgrade-link').on('click', function () {
			window.location = "/upgrade/";
		});

		if (window.cj.user) {
			if (!jQuery.cookie('existing_merchant')) {
				var domain = location.hostname.split('.').slice(-2).join('.');
				jQuery.cookie('existing_merchant', window.cj.user.email, {
					expires: 10000,
					path: '/',
					domain: domain
				});
			}
		}
		// Open Expiration Modal if the merchant is expired or past due on their bill
		if ((window.cj.isExpired || window.cj.isPastDue) && !window.cj.is_admin) {
			var opts = {};
			// don't let the modal close if expired but it can if only past due
			if (window.cj.isExpired) {
				opts.backdrop = 'static';
				opts.keyboard = false;
			}

			var expirationModal = new ExpirationModalView(opts);
			expirationModal.render();
		} else if (window.cj.needCJPMP && !window.cj.is_admin && window.location.pathname !== "/payments/") {
			// This is in an else because we don't want to show it AND the expired store modal.
			var hide_cjp_upgrade_modal = localStorage.getItem('hide_cjp_upgrade_modal');
			if (!hide_cjp_upgrade_modal || Date.now() - hide_cjp_upgrade_modal > 86400000) {
				localStorage.removeItem('hide_cjp_upgrade_modal');
				var CJPModal = new CratejoyPaymentsModalView({ "backdrop": 'static', "keyboard": false });
				CJPModal.render();
			}
		} else if (window.cj.cjpSetupIssues && !window.cj.is_admin) {
			// This is in an else because we don't want to show it AND either of the the expired store modal or the setup CJP modal.
			var CJPSetupIssuesModal = new CratejoyPaymentsSetupIssuesModalView({ "backdrop": 'static', "keyboard": false });
			CJPSetupIssuesModal.render();
		}

		var seen_new_payments_modal = localStorage.getItem('hide_new_payments_modal');
		if (window.location.pathname === "/payments/" && !window.cj.features.no_cratejoy_payments && !seen_new_payments_modal) {
			var NewPaymentsScreenModal = new NewPaymentsScreenModalView({ "backdrop": 'static', "keyboard": false });
			NewPaymentsScreenModal.render();
		}

		if (window.user && window.user.id) {
			Stats.identify(window.user.id);
		}

		// Track vendor impressions
		Stats.trackImpression('dashboard');

		// Should attach FullStory URL to Sentry Issues when possible
		FullstoryUtil.sendUrlToSentry();

		jQuery('.dismiss-label').click(function () {
			var $selectedGlobalLabel = jQuery(this).closest('.global-label');
			$selectedGlobalLabel.css({
				'min-height': 0
			});
			$selectedGlobalLabel.slideUp(180);

			jQuery.ajax({
				url: '/subscription_model/clear_bulk_change_renewal_date_status',
				type: 'POST'
			});
		});

		jQuery('[data-track="help_article"]').click(function () {
			/**
			 * Help Article Tracking
			 *
			 * Listens for external links (such as zendesk articles) and logs the event in Statjoy
			 *
			 * Links should be formatted with the following attributes
			 * :data-track="help_article"
			 * :data-source="where_link_was_displayed"
			 * :data-destination="slug_indicating_the_article"
			 *
			 * i.e. <a href="https://help.cratejoy.com/hc/en-us/articles/203759189"
			 * data-track="help_article" data-source="dashboard_banner" data-destination="test_mode">
			 *
			 */

			var $this = jQuery(this);
			var source = $this.attr('data-source');
			var slug = $this.attr('data-destination');

			var href = $this.attr('href').split('/');
			var destinationSlug = href.pop() || href.pop();  // doing it twice handles trailing slash

			if (source && slug) {
				Stats.trackGeneric('help_article_click', {
					'store_id': window.cj.store.id,
					'store_name': window.cj.store.name,
					'step_name': source,
					'article_slug': slug,
					'article': destinationSlug
				});
			}
		});

		if (localStorage.getItem('hide_listing_rejected')) {
			jQuery("#listing-rejected-banner").hide();
		} else {
			jQuery("#listing-rejected-banner").show();
		}

		// Display global banner prompting adding support e-mail if it's been > 24 hours since it was dismissed.
		var supportEmailBannerDismissedTS = localStorage.getItem('hide_no_support_email');
		if (!supportEmailBannerDismissedTS || Date.now() - supportEmailBannerDismissedTS > 86400000) {
			jQuery("#missing-support-email-banner").show();
		} else {
			jQuery("#missing-support-email-banner").hide();
		}

		// Dismisses a global banner, which displays for any logged in admin
		// This is not the same as a Global Notification (e.g. exports)
		jQuery('.dismiss-global-banner').click(function (e) {
			var banner = jQuery(e.target).closest('.alert-container');
			var bannerId = banner.data('banner-type');
			var storageKey = 'hide_' + bannerId;

			try {
				localStorage.setItem(storageKey, Date.now());
			} catch (error) {
				console.error(error);
			}

			jQuery(banner).remove();
		});

		jQuery('#foofighter').remove();

		// Upgrade modal.
		jQuery(".upgrade-gated").click(function (e) {
			console.log("Clicked Upgrade Gated Feature");
			(new UpgradeNoticeModalView()).done(function (doUpgrade) {

				if (doUpgrade) {
					window.location = "/upgrade/?new_plans=True";
				}
			}).open();
		});
	});

