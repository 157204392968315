console.log("LOADED: cookie_model.js");

define('vendor/components/cookie_model',[
		'jquery',
		'jquery-cookie',
		'underscore',
		'backbone'],
	function(
		jQuery,
		jQueryCookie,
		_,
		Backbone) {

	var CookieModel = Backbone.Model.extend({
		initialize: function(attrs, options) {
			_.bindAll(this, 'cookieSave');

			if(options.expires) {
				this.expires = options.expires;
			}

			this.cookieName = options.cookieName;
			this.blacklist = options.blacklist || [];

			this.fetch();
			this.on('change', this.cookieSave);
		},

		cookieSave: function() {
			var cookieOptions = {
				path: '/'
			};

			if(this.expires) {
				cookieOptions.expires = this.expires;
			}

			jQuery.cookie(
				this.cookieName,
				JSON.stringify(_.omit(this.toJSON(), this.blacklist)),
				cookieOptions);
		},

		fetch: function() {
		    // If the cookie doesn't exist, just fail silently
		    try {
				var data = JSON.parse(jQuery.cookie(this.cookieName));
				this.set(data);
			}
			catch(e) {}
		}
	});

	return CookieModel;
});

