console.log("LOADED: cratejoy_payments_modal_view.js");

define('vendor/views/cratejoy_payments_modal_view',[
	'underscore',
	'backbone',
	'vendor/views/cj_modal',
	'vendor/components/frontend_stats',
	'stache!vendor/tpl/cratejoy_payments_modal',
	'stache!vendor/tpl/cratejoy_payments_modal_dismiss_cta'],
	function (
		_,
		Backbone,
		CJModal,
		FrontendStats,
		CratejoyPaymentsModalTpl,
		CratejoyPaymentsModalDismissCtaTpl
	) {

		var CratejoyPaymentsModal = Backbone.View.extend({
			events: {},

			initialize: function (options) {
				this.parentView = options.parentView;
			},

			render: function () {
				this.$el.html(CratejoyPaymentsModalTpl({}));
			},

			submit: function () {
				if (!window.cj.features.cjp_two_step_cta) {
					window.location = "/payments/";
				}
			},

			onComplete: function () {
				window.location = "/payments/";
			},

			onCancel: function () {
				localStorage.setItem('hide_cjp_upgrade_modal', Date.now());
				FrontendStats.trackGeneric('hide_cjp_upgrade_modal', {});
			}
		});

		var CratejoyPaymentsModalDismissCta = Backbone.View.extend({
			events: {},

			initialize: function (options) {
				this.parentView = options.parentView;
			},

			render: function () {
				this.$el.html(CratejoyPaymentsModalDismissCtaTpl({}));
			},

			submit: function () {
				window.location = "/payments/";
			},

			onCancel: function () {
				localStorage.setItem('hide_cjp_upgrade_modal', Date.now());
				FrontendStats.trackGeneric('hide_cjp_upgrade_modal', {});
			}
		});

		var CratejoyPaymentsModalView = CJModal.extend({
			initialize: function (options) {
				options.modalId = 'billing-modal';
				options.modalClass = 'billing-modal';

				options.steps = [
					{
						name: "step1",
						body: CratejoyPaymentsModal,
						model: this.model,
						button: {
							list: [
								{
									template: '.modal-button-templates .modal-cancel',
									label: 'Show again tomorrow',
									class: 'dont-connect',
									id: 'refuse',
									position: 'left'
								},
								{
									template: '.modal-button-templates .modal-complete-positive',
									label: 'Set up payments',
									class: 'btn-positive',
									id: 'setup-cjp',
									position: 'right'
								}
							]
						}
					}
				];
				if (false) { // Keeping this around just in case we need to bring back second step
					options.steps.push(
						{
							name: "step2",
							body: CratejoyPaymentsModalDismissCta,
							model: this.model,
							button: {
								list: [
									{
										template: '.modal-button-templates .modal-cancel',
										label: 'I acknowledge the above',
										class: 'dont-connect',
										id: 'really-refuse',
										position: 'left'
									},
									{
										template: '.modal-button-templates .modal-next',
										label: 'Set up payments',
										class: 'btn-positive',
										id: 'setup-cjp',
										position: 'right'
									}

								]
							}
						}
					);
				}
				CJModal.prototype.initialize.call(this, options);
			},

		});

		return CratejoyPaymentsModalView;
	});

