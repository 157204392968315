define('vendor/components/search_autocomplete',['jquery',
		'underscore',
		'typeahead',
		'vendor/components/es_search_util',
		'stache!vendor/tpl/search_auto_result',
		'stache!vendor/tpl/search_auto_category',
		'stache!vendor/tpl/search_auto_view_all'],
	function(jQuery,
			 _,
			 jQueryTypeahead,
			 ES_Utils,
			 SearchAutoResultTpl,
			 SearchAutoCategoryTpl,
			 SearchAutoViewAllTpl) {

		var $search = jQuery('#navbar-search-input');
		if($search.length === 0) {
			return;
		}
		var shipmentNames = [];

		// Set form state based on input
		var $searchForm = jQuery('#navbar-search-form');
		$search.on('keyup', function() {
			if($search.val().length > 0) {
				$searchForm.addClass('navbar-search-form-valid');
			} else {
				$searchForm.removeClass('navbar-search-form-valid');
			}
			shipmentNames = [];
		});

		var throttled = {
			customers:		new ES_Utils.ESThrottled('customers', {throttle: 1000}),
			subscriptions:	new ES_Utils.ESThrottled('subscriptions', {throttle: 1000}),
			shipments:		new ES_Utils.ESThrottled('shipments', {throttle: 1000})
		};

		var ES_Search = function(index) {
			return function findMatches(query, sync_callback, async_callback) {
				throttled[index].execute(query, async_callback);
			};
		};

		$search.typeahead({hint: false}, [
			{
				name: 'customers',
				limit: 10,
				source: ES_Search('customers'),
				displayKey: 'name',
				templates: {
					header: function(obj) {
						return SearchAutoCategoryTpl({
							'name': 'Customers',
							'viewLink': '/customers/?search_q=' + obj.query
						});
					},
					suggestion: function(hit) {
						var result = hit.name;
						return SearchAutoResultTpl({
							itemLink: '/customer/' + hit.id,
							result: result
						});
					},
					footer: SearchAutoViewAllTpl
				}
			},
			{
				name: 'subscriptions',
				limit: 10,
				source: ES_Search('subscriptions'),
				displayKey: 'id',
				templates: {
					header: function(obj) {
						return SearchAutoCategoryTpl({
							'name': 'Subscriptions',
							'viewLink': '/subscriptions/?search_q=' + obj.query
						});
					},
					suggestion: function(hit) {
						var result = '#' + hit.id + ' (' + hit.customer_name + ')';
						return SearchAutoResultTpl({
							itemLink: '/subscription/' + hit.id,
							result: result
						});
					},
					footer: SearchAutoViewAllTpl
				}
			},
			{
				name: 'shipments',
				limit: 10,
				source: ES_Search('shipments'),
				display: function(hit) {
					return hit.customer_name;
				},
				templates: {
					header: function(obj) {
						return SearchAutoCategoryTpl({
							'name': 'Shipments',
							'viewLink': '/shipments/?search_q=' + obj.query
						});
					},
					suggestion: function(hit) {
						var result = null;
						if (!_.contains(shipmentNames, hit.customer_name)) {
							shipmentNames.push(hit.customer_name);
							result = 'Shipments for ' + hit.customer_name;

						}
						return SearchAutoResultTpl({
							itemLink: '/shipments/?search_q=' + hit.customer_name,
							result: result
						});
					},
					footer: SearchAutoViewAllTpl
				}
			}
		]).on('typeahead:select', function(event, selection) {
			if($searchForm.find('.tt-cursor').length === 0) {
				// This is a link click. Let it take over
				return;
			}

			$searchForm.addClass('navbar-search-form-valid');

			// Grab the link and head over there
			var targetLink = $searchForm.find('a.tt-cursor');
			window.location.href = targetLink.attr('href');
		});

		// Auto-focus search bar (on whitelisted pages)
		var pages_w_autofocus = [
			'/launch',
			'/v/',
		];
		_.each(pages_w_autofocus, function(page) {
			if(window.location.pathname.indexOf(page) === 0) {
				if($search.val()) {
					$search.select();
				} else {
					$search.focus();
				}
			}
		});
	});

