/*
 * Vendor-specific wrapper for statjoy.js
 */

function frontendStats(Statjoy) {
	/* jshint ignore:start */
	// Object.assign polyfill from MDN:
	"function"!=typeof Object.assign&&Object.defineProperty(Object,"assign",{value:function(e,t){"use strict";if(null==e)throw new TypeError("Cannot convert undefined or null to object");for(var n=Object(e),r=1;r<arguments.length;r++){var o=arguments[r];if(null!=o)for(var c in o)Object.prototype.hasOwnProperty.call(o,c)&&(n[c]=o[c])}return n},writable:!0,configurable:!0});
	/* jshint ignore:end */

	if(window.enableStatjoy) {
		Statjoy.init("vendor_client", window.cj.session_id);
	}

	var getSlug = function() {
		var slug = location.pathname;
		if(slug.length) {
			if(slug[0] == "/") {
				slug = slug.substr(1);
			}

			return slug.split("/")[0];
		}

		return slug;
	};

	var getProps = function(dict) {
		dict = dict || {};
		var userData = {logged_in: false, user_distinct_id: null};

		if(window.user) {
			userData.user_name = window.user.name;
			userData.user_email = window.user.email;
			userData.logged_in = true;
			userData.user_distinct_id = window.user.id;	// Mixpanel hides the "distinct_id" namespace as a viewable property
		}

		if(window.cj.has_store) {
			var storeData = {
				store_id: window.cj.store.id,
				store_name: window.cj.store.name,
				store_subdomain: window.cj.store.settings.subdomain,
				store_is_internal: window.cj.store.settings.is_internal,
			};
			Object.assign(dict, storeData);
		}

		if(typeof window.cj.has_provider !== "undefined") {
			Object.assign(dict, {set_payment_gateway: window.cj.has_provider});
		}

		if(typeof window.cj.test_mode !== "undefined") {
			Object.assign(dict, {test_mode: window.cj.test_mode});
		}

		var paths = location.pathname.split('/');
		var pathsDict = {};
		var ctr = 0;
		paths.forEach(function(path) {
			if (path) {
				pathsDict['path:'+ctr] = path;
				ctr++;
			}
		});

		Object.assign(dict, pathsDict);

		var paying_merchant = false;
		if(window.cj.billing_plan &&
		   window.cj.billing_plan.instances[0].price > 0) {
			paying_merchant = true;
		}

		try {
			if (window.vendorFS && window.vendorFS.getCurrentSessionURL) {
				dict.fullstory_url = window.vendorFS.getCurrentSessionURL(true);
			}
		} catch (e) {
			console.warning('Could not get fullstory session URL');
		}

		return Object.assign(dict, userData, {
			source: 'vendor',
			full_url: location.href,
			host: location.hostname,
			path: location.pathname,
			slug: getSlug(),
			paying_merchant: paying_merchant
		});
	};

	var track = function() {

		var eventName = arguments[0];
		var propsDict = arguments[1];

		if(window.enableStatjoy) {
			Statjoy.track(eventName, propsDict).then(function(res) {
				console.log("STATS: Statjoy event tracked successfully: " + eventName);
			}, function(error) {
				console.log("STATS: Statjoy tracked FAILED: " + eventName);
				console.error(error);
			});
		} else {
			console.debug('stat event fired with statjoy disabled:', eventName, propsDict);
		}
	};

	var exports = {};

	exports.trackInternal = track;

	exports.trackGeneric = function(eventName, dict) {
		dict = getProps(dict);
		track(eventName, dict);
	};

	exports.trackError = function(errorMessage, dict) {
		dict = getProps(dict);
		dict.error_message = errorMessage;

		try {
			if (window.vendorFS && window.vendorFS.getCurrentSessionURL) {
				// Currently unused column on the error event. Want this in addition to the 'fullstory_url' property for fast
				// access on this common usecase.
				dict.selector = window.vendorFS.getCurrentSessionURL(true);
			}
		} catch (e) {
			console.warning('Could not get fullstory session URL');
		}

		track('error', dict);
	};

	exports.trackImpression = function(view) {
		if (!window.performance) {
			return;
		}
		var dict = {
			view: view,
			page_type: window.cj.requestDetails.request_rule,
			// This property is the time from when the browser received the last byte of the document
			// to the time the DOM is interactive
			utm_carton: window.performance.timing.domInteractive  - window.performance.timing.responseEnd,
			// This property is the time from when the browser received the last byte of the document to the time the DOM is loaded
			step_name: window.performance.timing.domContentLoadedEventEnd - window.performance.timing.responseEnd,
			// This property is the time from when the browser began navigating to the page, to when it finished.
			selector: window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart,
		};
		dict = getProps(dict);

		track('impression', dict);
	};

	exports.identify = function(userId) {
		console.log("STATS: User identified: " + userId);

		if(window.enableStatjoy) {
			if(Statjoy.isIdentified()) {
				if(Statjoy.getUserId() !== userId) {
					// This is possible if another user was logged in and like...deleted their
					// cratejoy session cookie but not their statjoy session cookie (so that a
					// logout was never triggered). Shouldn't really happen but
					// it's easy to check for it and log out preemptively
					console.log("STATS: Statjoy already has a user logged in, logging them out");
					exports.trackGeneric('logout', { triggered: 'auto-identify'});

					Statjoy.logout();
				}
			}
			else {
				Statjoy.login(userId);

			}
		}

		exports.trackGeneric('login');
	};

	exports.trackClick = function (view, location, properties) {
		var dict = {
			view: view, location: location
		};
		if (properties) {
			dict = Object.assign(dict, properties);
		}
		dict = getProps(dict);
		track('click', dict);
	};

	exports.trackFlow = function(flowName, stepNum, stepName, success) {
		var dict = {
			step_name: stepName,
			step_num: stepNum,
			success: success
		};
		dict = getProps(dict);

		var eventName = 'flow_' + flowName;
		exports.trackGeneric(eventName, dict);
	};

	exports.logout = function() {
		console.log("STATS: Logout triggered");

		exports.trackGeneric('logout', { triggered: 'manual'});

		if(window.enableStatjoy) {
			Statjoy.logout();
		}
	};

	return exports;
}

// Make this module work with requireJS or browserify
if (typeof require === 'function' && typeof exports !== 'undefined') {
	var Statjoy = require('statjoy');
	module.exports = frontendStats(Statjoy);
} else if (typeof define === 'function' && define.amd) {
	console.log("LOADED: frontend_stats.js");
	define('vendor/components/frontend_stats',['statjoy'], frontendStats);
}
;
