console.log("LOADED: login_view.js");

define('vendor/components/adaptive_form_view',[
		'jquery',
		'underscore',
		'backbone'
	],
	function (jQuery, _, Backbone) {

		var AdaptiveFormView = Backbone.View.extend({

			events: {
				"click .placeholder-label": 'onLabelClick',
				"keydown .field-item input": 'onKeyDown',
				"keyup .field-item input": 'onKeyUp',
				"focus .field-item input": 'onFocus',
				"blur .field-item input": 'onBlur',
			},

			render: function() {
				this.clearFieldClasses();
			},

			clearFieldClasses: function() {
				jQuery('.field-item input').each(function () {
					var $thisParent = jQuery(this).parent();
					if (this.value.length < 1) {
						$thisParent.removeClass('filled');
					} else {
						$thisParent.addClass('filled');
					}
					$thisParent.removeClass('active');
				});
			},

			onKeyUp: function(e) {
				if(e.target.value.length > 0) {
					jQuery(e.target).parent().addClass('filled');
				} else {
					jQuery(e.target).parent().removeClass('filled');
				}

				if(jQuery('.field-item.filled').length == jQuery('.field-item').length) {
					jQuery('.adaptive-form').addClass('valid');
				} else {
					jQuery('.adaptive-form').removeClass('valid');
				}
			},

			onFocus: function (e) {
				this.clearFieldClasses();

				jQuery(e.target.parentNode).addClass('active');
			},

			onBlur: function (e) {
				this.clearFieldClasses();
			},

			onLabelClick: function (e) {
				jQuery(e.target).parent().toggleClass('active');
				jQuery(e.target).parent().find('input').focus();
			}
		});
		return AdaptiveFormView;
	});

