console.log("LOADED: login_view.js");

define('vendor/views/login_view',[
		'jquery',
		'underscore',
		'backbone',
		'vendor/components/adaptive_form_view',
		'vendor/views/recaptcha_view'
	],
	function (
		jQuery,
		_,
		Backbone,
		AdaptiveFormView,
		RecaptchaView
	) {
		var LoginView = AdaptiveFormView.extend({

			events: function(){
				return _.extend({}, AdaptiveFormView.prototype.events, {
					'click #login-button': 'onCreateSubmit'
				});
			},

			initialize: function(options) {
				this.recaptchaView = new RecaptchaView();
				this.$recaptchaInput = this.$el.find('#recaptcha-token');
				this.$loginForm = this.$el.find('#login-form');
			},

			render: function() {
				this.recaptchaView.load();
			},

			onCreateSubmit: function(e) {
				e.preventDefault();
				this.recaptchaView.execute(_.bind(this.onLoginSubmit, this));
			},
			
			onLoginSubmit: function(token) {
				this.$recaptchaInput.val(token);
				this.$loginForm.submit();
				this.recaptchaView.reset();
			}
		});

		return LoginView;
	});

