console.log("LOADED: global_notifications.js");

define('vendor/components/global_notifications',[
	'underscore',
	'jquery',
	'backbone',
	'vendor/components/global_notifications/header_view',
	'vendor/components/global_notifications/exports',
	'vendor/components/global_notifications/shipstation',
	'vendor/components/global_notifications/shippingeasy'
], function(
		_,
		jQuery,
		Backbone,
		GlobalNotificationsHeaderView,
		ExportViews,
		ShipstationViews,
		ShippingEasyViews
	) {

	// Create NotificationState model.
	var NotificationState = Backbone.Model.extend({

		initialize: function () {
			// Pull the actual export_id out of the State ID to use in URLs and such
			this.set('export_id', this.id.split(':')[1]);
		},

		url: function () {
			return '/_notifications/' + this.get('id') + '/'+this.get('State');
		},

		setConflict: function () {
			this.set('conflict', true);
		}
	});
	// Create Collection of states.
	var NotificationStateCollection = Backbone.Collection.extend({

		url: '/_notifications/',

		model: NotificationState

	});

	// Note that the NotificationStateView's should not be here; they will be improved from the type modules
	var GlobalNotificationsView = Backbone.View.extend({

		el: jQuery("#global-labels"),

		initialize: function(options) {
			this.collection = options.collection;
			this.ViewMap = options.ViewMap;

			this.listenTo(this.collection, 'add', this.addOne);
			this.listenTo(this.collection, 'reset', this.addAll);

			this.collection.reset(window.cj.global_notification_states);

			if (this.collection.length) {
				this.startPolling();
			}

			this.$('#global-labels-container').css({
				'display': 'none'
			});

			var self = this;
			this.$el.on('global_notif_trigger', function() {
				self.startPolling();
			});

			this.render();
		},

		render: function() {

			var headerView = new GlobalNotificationsHeaderView({
				collection: this.collection,
				parent: this
			});

			this.$('#global-labels-header').html(headerView.render().el);

			this.addAll();
		},

		addOne: function(state) {

			var View = this.ViewMap[state.get('Type')][state.get('State')];

			if (!View) {
				console.log('No view defined; skipping '+state.get('Type')+':'+state.get('State'));
				return;
			}

			var view = new View({
				model: state,
				parent: this
			});
			this.$('#global-labels-container').append(view.render().el);
		},

		addAll: function () {
			this.$('#global-labels-container').empty();
			this.collection.each(this.addOne, this);
		},

		setCollapse: function (state, cb) {
			/*
				* state ∈ {'Opened', 'Closed'}
				*/

			cb = cb || function () {};

			// console.log('Setting Global Notifications collapse');
			this.$('#global-labels-container').css({
				'min-height': 0
			});

			if (state == 'Closed') {
				this.$('#global-labels-container').slideUp(180, cb);
			} else {
				this.$('#global-labels-container').slideDown(180, cb);
			}
		},
		startPolling: function () {
			if (this._poll) {
				clearInterval(this._poll);
			}
			
			// First fetch *ALMOST* immediately, then start polling
			setTimeout(_.bind(function() {
				this.collection.fetch({
					reset: true,
					success: _.bind(function (collection) {
						if (collection.where({ 'State': 'Pending' }).length === 0 &&
							collection.where({ 'State': 'Processing' }).length === 0) {
							this.stopPolling();
						}
					}, this)
				});
			}, this), 2000);

			this._poll = setInterval(_.bind(function () {
				this.collection.fetch({
					reset: true,
					success: _.bind(function (collection) {
						if (collection.where({ 'State': 'Pending' }).length === 0 &&
							collection.where({ 'State': 'Processing' }).length === 0) {
							this.stopPolling();
						}
					}, this)
				});
			}, this), 60000);
		},

		stopPolling: function () {
			if (!this._poll) {
				return;
			}

			clearInterval(this._poll);
		},

		alertConflict: function (body) {
			/*
				* This function can be passed a body, and then determine how to handle multiple types of 'conflict'.
				* Note that this function is rather hacky, and if you want to extend Global Notifications with anything
				* that could have conflicts, you will likely have to hack something together here
				*/

			if (body.export_id) {
				/* Handle export conflicts */
				// Find the right export in progress
				var e = this.collection.get('export:'+body.export_id);

				if (e) {
					// Set a conflict attribute with the time of the conflict. The view will emphasise the existing export's banner and make it say "already going!"
					e.setConflict();
					this.startPolling();
				} else {
					// This probably will never happen, but should probably log this to mixpanel or something
					// Todo: Log this to mixpanel
				}
			}
		}
	});

	var instance = new GlobalNotificationsView({
		collection: new NotificationStateCollection(),
		ViewMap: {
			'Export': ExportViews,
			'Shipstation': ShipstationViews,
			'ShippingEasy': ShippingEasyViews,
		}
	});

	window.GlobalNotificationsView = instance;

	return instance;
});


