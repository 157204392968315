console.log("LOADED: login_page.js");

define('vendor/pages/login_page',[
		'jquery',
		'underscore',
		'vendor/views/login_view'
	],
	function(
		jQuery,
		_,
		LoginView
	) {
		var loginView = new LoginView({
			el: jQuery('#login-view')
		});
		loginView.render();
	});

