console.log("LOADED: global_notifications:header");

define('vendor/components/global_notifications/header_view',['underscore',
		'jquery',
		'backbone',
		'stache!vendor/components/global_notifications/tpl/header_opened',
		'stache!vendor/components/global_notifications/tpl/header_closed',
		'vendor/components/cookie_model'], function(
			_,
			jQuery,
			Backbone,
			OpenedTpl,
			ClosedTpl,
			CookieModel) {

		function combineSentence(components) {
			var l = components.length;

			var sentence = '';
			for (var i = 0; i < l; i++) {
				sentence += components[i];

				if (i < l - 2) {
					sentence += ', ';
				} else if (i == l - 2) {
					sentence += ' and ';
				}
			}

			return sentence + '.';
		}

		var previousState = 'Closed';

		var HeaderView = Backbone.View.extend({
			tagName: 'div',

			events: {
				"click .toggle-collapse" : "toggle"
			},

			initialize: function (options) {

				this.collection = options.collection;
				this.parent = options.parent;

				this.state = 'Closed';

				this.listenTo(this.collection, 'add', this.render);
				this.listenTo(this.collection, 'remove', this.render);
				this.listenTo(this.collection, 'reset', this.render);
				this.listenTo(this.parent, 'forceOpen', this.setOpen);
			},

			setOpen: function () {
				this.state = 'Opened';
			},

			buildSummary: function (counts) {

				var pendingComponent = counts.Pending + ' job' + (counts.Pending > 1 || counts.Pending === 0 ? 's' : '') + ' in progress';
				var successComponent = counts.Success + ' completed job' + (counts.Success > 1 || counts.Success === 0 ? 's' : '');
				var failureComponent = counts.Failure + ' job' + (counts.Failure > 1 || counts.Failure === 0 ? 's' : '') + ' that failed to complete';

				var components = [];
				if (counts.Pending) {
					components.push(pendingComponent);
				}
				if (counts.Success) {
					components.push(successComponent);
				}
				if (counts.Failure) {
					components.push(failureComponent);
				}

				return 'You have ' + combineSentence(components);
			},
			render: function () {

				var tpl = this.state == 'Closed' ? ClosedTpl : OpenedTpl;

				var counts = {
					'Pending': this.collection.where({ 'State': 'Pending'}).length,
					'Success': this.collection.where({ 'State': 'Success'}).length,
					'Failure': this.collection.where({ 'State': 'Failure'}).length,
					'Dismissed': this.collection.where({ 'Dismissed': true}).length,
					'Total': this.collection.length
				};

				if (counts.Total - counts.Dismissed > 1) {
					// We only want to display the summary header if we have more than 1 thing to show.
					var headerText = 'You have ' + counts.Pending + ' process' + (counts.Pending > 1 || counts.Pending === 0 ? 'es' : '') + ' running';

					if (counts.Success > 0) {
						headerText += ', and ' + counts.Success + ' completed job' + (counts.Success > 1 ? 's' : '') + '.';
					} else {
						headerText += '.';
					}

					this.$el.html(tpl({
						counts: counts,
						header: this.buildSummary(counts)
					}));

					// Just incase it's previously been hidden by the step below
					this.$el.slideDown(180);
				} else if (counts.Total - counts.Dismissed === 1) {
					// If only one, then just show it. We do this by NOT showing the header, and instead expanding the collapse segment
					this.state = 'Opened';
					this.parent.setCollapse(this.state);
					this.$el.css({
						'min-height': 0
					});
					this.$el.slideUp(180, _.bind(function () {
						this.$el.html("");  // omg such hackz
					}, this));

				} else {
					this.state = 'Closed';
					this.parent.setCollapse(this.state);
					this.$el.html("");  // omg such hackz
				}
				return this;
			},

			toggle: function () {
				this.state = this.state == 'Closed' ? 'Opened' : 'Closed';

				this.parent.setCollapse(this.state);

				this.render();
			}
		});

		return HeaderView;
	});

