console.log("LOADED: global_notifications:shippingeasy");

define(
	'vendor/components/global_notifications/shippingeasy',['underscore',
		'jquery',
		'backbone',
		'stache!vendor/components/global_notifications/tpl/shippingeasy_success',
		'stache!vendor/components/global_notifications/tpl/shippingeasy_failure',
		'stache!vendor/components/global_notifications/tpl/shippingeasy_pending',
		'stache!vendor/components/global_notifications/tpl/shippingeasy_processing',
		'vendor/components/cookie_model'], function(
		_,
		jQuery,
		Backbone,
		SuccessTpl,
		FailureTpl,
		PendingTpl,
		ProcessingTpl,
		CookieModel) {

	var failedRetries = {};
	var alreadyPending = {};
	var alertNextTime = {};

	var Base = Backbone.View.extend({
		tagName: 'div',
		events: {
			"click .dismiss-label" : "dismissNotif",
		},

		initialize: function (options) {
			this.model = options.model;
			this.parent = options.parent;

			this.listenTo(this.model, 'change', this.render);
			this.listenTo(this.model, 'destroy', this.remove);
		},

		render: function () {
			this.$el.html(this.template(this.model.toJSON()));
			return this;
		},

		dismissNotif: function () {
			this.$el.css({
				'min-height': 0
			});
			this.$el.slideUp(180, _.bind(this.model.destroy, this.model));
		},

	});

	var Success = Base.extend({
		template: SuccessTpl,
	});

	var Pending = Base.extend({
		template: PendingTpl,
	});

	var Processing = Base.extend({
		template: ProcessingTpl,

		render: function () {
			console.log('Processing Render');

			if (this.model.get('Dismissed')) {
				if (!alreadyPending[this.model.id]) {
					this.$el.html("");
					return this;
				} else {
					this.model.set('Dismissed', false);  // We want to show this again
				}
			}

			if (alreadyPending[this.model.id]) {
				this.model.set('conflicted', true);
			}
			this.$el.html(this.template(this.model.toJSON()));

			if (alertNextTime[this.model.id]) {
				alertNextTime[this.model.id] = false;

				this.parent.trigger('forceOpen');

				this.parent.setCollapse('Opened', _.bind(function () {
					var $globalLabel = this.$('.global-label');
					$globalLabel.addClass('pulse');
					// Remove class shortly after for repeatability
					setTimeout(function () {
						$globalLabel.removeClass('pulse');
					}, 500);
				}, this));
			}
			return this;
		},

		dismissNotif: function () {

			// Remove the override that forces it to show even when pending
			alreadyPending[this.model.id] = false;

			this.$el.css({
				'min-height': 0
			});
			this.$el.slideUp(180, _.bind(this.model.destroy, this.model));
		}
	});

	var Failure = Base.extend({
		template: FailureTpl,
	});

	return {
		'Success': Success,
		'Pending': Pending,
		'Processing': Processing,
		'Failure': Failure
	};
});


