console.log("LOADED: es_search_utils.js");

define('vendor/components/es_search_util',['jquery', 'underscore'],
	function(jQuery, _) {
		var ESThrottled = function(index, args, params) {
			this.query		= null;
			this.index		= index;
			this.args		= args || {};
			this.params		= params || {};
			this.reqNo		= 0;
			this.resNo		= 0;	// Don't accept responses less than current resno
			this.qmap		= {};
			this.fn			= _.throttle(this._execute, this.args.throttle || 5000, {leading:false, trailing:true});
		};

		ESThrottled.prototype.execute = function(query, callback) {
			this.query			= query;
			this.qmap[query]	= callback;
			this.fn();
		};

		ESThrottled.prototype._execute = function() {
			this.reqNo += 1;

			jQuery.ajax(
				'/global_search/' + this.index,
				{
					data: _.extend({
						'query': this.query,
						'token': window.cj.search.search_token,
						'reqno': this.reqNo
					}, this.params),
					success: function (result, status_text) {
						var resNo = parseInt(result.request_num);

						if(resNo >= this.resNo) {
							this.resNo = resNo;
							this.qmap[result.query](result.hits);
						}
					},
					context: this
				}
			);
		};

		return {
			ESThrottled: ESThrottled
		};
	}
);

