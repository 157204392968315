console.log("LOADED: recaptcha_view.js");

define('vendor/views/recaptcha_view',[
	'jquery',
	'underscore',
	'backbone'
], function(jQuery, _, Backbone) {

	var RecaptchaView = Backbone.View.extend({

		initialize: function(options) {
			_.bindAll(this, 'recaptchaLoaded', 'execute', 'reset', 'getResponse', 'challengeComplete', 'load');

			this.loadCallbacks = [];  // callbacks fired after the recaptcha js script / api loads
			this.challengeCallbacks = [];  // callbacks fired after a challenge execution is complete

			this.callbackName = 'GoogleRecaptchaResolved';
			window[this.callbackName] = this.challengeComplete;
		},

		load: function() {
			if (window.grecaptcha) {
				return false;
			}

			var loaded = _.bind(function() {
				this.recaptchaId = window.grecaptcha.render('google-recaptcha', {
					sitekey: window.cj.googleRecaptchaSiteKey,
					size: 'invisible',
					callback: this.callbackName
				});
			}, this);

			this.loadCallbacks.push(loaded);
			if (!document.querySelector('#google-recaptcha')) {
				this.injectScript();
			}
		},

		challengeComplete: function(token) {
			while (this.challengeCallbacks.length) {
				var challengeCallback = this.challengeCallbacks.pop();
				challengeCallback(token);
			}
		},

		recaptchaLoaded: function() {
			while (this.loadCallbacks.length) {
				var loadCb = this.loadCallbacks.pop();
				loadCb();
			}
		},

		injectScript: function() {
			// inject the script api tag into the DOM
			// we have to do this after the post-load callbacks are defined
			// according to the recaptcha docs
			// https://developers.google.com/recaptcha/docs/invisible#programmatic_execute

			window.GoogleRecaptchaLoaded = this.recaptchaLoaded;

			var script = window.document.createElement('script');
			script.id = 'google-recaptcha';
			script.src = 'https://www.google.com/recaptcha/api.js?hl=en&onload=GoogleRecaptchaLoaded&render=explicit';
			script.type = 'text/javascript';
			script.async = true;
			script.defer = true;
			script.onerror = function(err) {
				throw err;
			};

			window.document.body.appendChild(script);
		},

		execute: function(cb) {
			// execute the challenge, register a callback

			this.challengeCallbacks.push(cb);

			if (window.cj.useRecaptcha) {
				return window.grecaptcha.execute(this.recaptchaId);
			} else {
				// the back-end validates that _some_ token is sent, but on stage we dont
				// use recaptcha, which is why i'm using this string
				this.challengeComplete('faketoken');
			}
		},

		getResponse: function() {
			return window.grecaptcha.getResponse(this.recaptchaId);
		},

		reset: function() {
			// recaptcha tokens can only be used once, so we have to reset
			// after any unsuccessful requests to the back-end
			return window.grecaptcha.reset(this.recaptchaId);
		}

	});

	return RecaptchaView;
});

