console.log("LOADED: cratejoy_payments_setup_issues_modal_view.js");

define('vendor/views/new_cratejoy_payments_screen_modal_view',[
	'underscore',
	'backbone',
	'vendor/views/cj_modal',
	'stache!vendor/tpl/new_cratejoy_payments_screen_modal'
],
	function (
		_,
		Backbone,
		CJModal,
		NewPaymentsScreenModalTpl
	) {

		var NewPaymentsScreenModal = Backbone.View.extend({
			events: {},

			initialize: function (options) {
				this.parentView = options.parentView;
			},

			render: function () {
				this.$el.html(NewPaymentsScreenModalTpl({}));
			},

			submit: function () {
			},

			onComplete: function () {
				localStorage.setItem('hide_new_payments_modal', Date.now());
				FrontendStats.trackGeneric('hide_new_payments_modal', {});
			},

			onCancel: function () {
				localStorage.setItem('hide_new_payments_modal', Date.now());
				FrontendStats.trackGeneric('hide_new_payments_modal', {});
			}
		});

		var NewPaymentsScreenModalView = CJModal.extend({
			initialize: function (options) {
				options.modalId = 'billing-modal';
				options.modalClass = 'billing-modal';

				options.steps = [
					{
						name: "step1",
						body: NewPaymentsScreenModal,
						model: this.model,
						button: {
							list: [
								{
									template: '.modal-button-templates .modal-cancel',
									label: 'Continue to Payments',
									class: 'btn-positive',
									id: 'payments-continue',
									position: 'right'
								}
							]
						}
					}
				];
				CJModal.prototype.initialize.call(this, options);
			},

		});

		return NewPaymentsScreenModalView;
	});

