console.log('LOADED: upgrade_notice_model_view.js');

define('vendor/views/upgrade_notice_modal_view',['jquery',
		'underscore',
		'backbone',
		'backbone-modelbinder',
		'text!vendor/tpl/upgrade_notice_modal.html'],

	function(
			jQuery,
			_,
			Backbone,
			BackboneModelBinder,
			NoticeModalTpl) {

	var UpgradeNoticeModalView = Backbone.View.extend({
		className: 'modal bs-modal fade upgrade-notice-modal',
		events: {
			'show.bs.modal': 'onShow',
			'hidden.bs.modal': 'onHidden',
			'click #upgrade_btn': 'onUpgrade',
		},

		initialize: function () {
		},

		onShow: function() {
		},

		onHidden: function() {
			this.close(false);
		},

		onUpgrade: function () {
			this.close(true);
		},

		open: function() {
			this.render().$el.modal('show');

			return this;
		},

		close: function(upgrade) {
			this.remove();

			if(this.onDone) {
				this.onDone.call(this.onDoneCtx || this, upgrade);
			}

			return this;
		},

		done: function(onDone, ctx) {
			this.onDone = onDone;
			this.onDoneCtx = ctx;

			return this;
		},

		remove: function() {
			jQuery('.modal-backdrop').remove();
			Backbone.View.prototype.remove.call(this);
		},

		render: function() {
			this.$el.html(_.template(NoticeModalTpl, {}));
			this.delegateEvents();

			return this;
		}
	});

	return UpgradeNoticeModalView;
});

